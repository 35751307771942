<template>
<OModal ref="confirmModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{$t(modalOptions.title)}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                {{modalOptions.message}}
               
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</OModal>
</template>
<script setup>
    import OModal from 'o365.vue.components.Modal.vue';
    import {ref} from 'vue';
    const confirmModal = ref(null);
    const modalOptions = ref({
        title:"",
        message:"",
        
    })
    
</script>